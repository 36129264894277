import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { debounceTime, map, Observable, startWith, takeWhile } from 'rxjs';

// Generated by Copilot

@Component({
  selector: 'digital-platform-formly-field-autocomplete',
  template: `
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ props.label }}</mat-label>
      <input matInput (blur)="blur($event)" (keydown)="onKeydown($event)" [required]="props.required || false" [placeholder]="props.placeholder || ''" [formControl]="formControl" [matAutocomplete]="auto" [formlyAttributes]="field">
      <mat-autocomplete (optionSelected)="onSelection($event)" #auto="matAutocomplete">
        <ng-container>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option[props.optionKey]">
            {{ option[props.optionKey] }}{{ option[props.labelKey] ? ' - ' + option[props.labelKey] : '' }}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldInpAutoCompleteComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  // Generated by Copilot
  filteredOptions: Observable<any[]> = new Observable();
  private _allOptions: any[];
  private _isComponentActive: boolean;
  private _selectedValue: string;

  constructor() {
    super();
    this._allOptions = [];
    this._isComponentActive = true;
    this._selectedValue = '';
  }

  ngOnInit() {
    this._setupFilterOptions();
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this._allOptions.filter(option =>
      option[this.props.optionKey]?.toLowerCase().includes(filterValue) ||
      option[this.props.labelKey]?.toLowerCase().includes(filterValue)
    );
  }

  private _setupFilterOptions() {
    if (this.props.options$) {
      this.props.options$.pipe(takeWhile(() => this._isComponentActive)).subscribe((options: any[]) => {
        if (options && options.length) {
          this._allOptions = options;
          this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            map(value => this._filter(value))
          );
        }
      });
    } else {
      throw new Error('You must provide an options$ observable for the autocomplete field.');
    }
  }

  blur(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    if (target?.value && this._selectedValue !== target.value && this.formControl.valid) {
      const selectedOption = this._allOptions.find(option => option[this.props.optionKey] === target.value);
      this.props?.customChange(selectedOption || target?.value);
    }
  }

  onSelection(event: MatAutocompleteSelectedEvent) {
    const selectedOption = this._allOptions.find(option => option[this.props.optionKey] === event.option.value);
    this._selectedValue = event.option.value;
    this.props?.customChange(selectedOption);
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this._isComponentActive = false;
  }
}
