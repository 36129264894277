// Generated by Copilot
export const AccessConstants = {
  SEARCH_APPLICATION_ROUTE: 'search-application',
  APPLICATION_ADD_ROUTE: 'add-application-item',
  APPLICATION_DELETE_ROUTE: 'delete-application-item',
  APPLICATION_GET_ROUTE: 'application-item',
  APPLICATION_EDIT_ROUTE: 'application-item',
  APPLICATION_DELETE_MSG: 'Are you sure you want to delete the application?',
  APPLICATION_DELETE_MSG_ONE: 'Are you sure you want to delete ',
  APPLICATION_DELETE_DIALOG_TITLE: 'Delete Catalog',
  APPLICATION_DELETION_SUCCESS: 'Application deleted successfully',
  APPLICATION_ADD_SUCCESS: 'Application added successfully',
  APPLICATION_UPDATE_SUCCESS: 'Application updated successfully',
  REMOVE_CATALOG_APPLICATION_ROUTE: 'remove-catalog-from-applications',
  ADD_CATALOG_APPLICATION_ROUTE: 'add-catalog-to-application',
};
