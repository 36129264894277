import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'digital-platform-formly-input2',
  template: `
  <mat-form-field fisStyle appearance="outline" floatLabel="always">
    <mat-label>
      {{ props.label }}
      <span *ngIf="props.required">*</span>
    </mat-label>
    <input matInput [maxlength]="props.maxlength || 200" [readonly]="props.readonly" [formControl]="formControl"
           [formlyAttributes]="field" [placeholder]="props.placeholder || ''">
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
  </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldInput2Component extends FieldType<FieldTypeConfig> {
}
